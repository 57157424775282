import Store from "./Store";
import Notice from "./component/Notice";
const Api = {};

Api.url = "https://api.checkpot.tw-team.com/admin";

Api.getImage = (n) => {
    return "https://api.checkpot.tw-team.com/image/" + n + ".webp";
};

/*
tb.Send = async (id,text) => {
    return await axios({ url: `https://api.telegram.org/bot${support}/sendMessage?chat_id=${id}&text=${text}&parse_mode=HTML`});
}

tb.Invite = async (id,text) => {
    return await axios({ url: `https://api.telegram.org/bot${informer}/createChatInviteLink?chat_id=-1001632792263&member_limit=1`});
}
*/

Api.fetchAuth = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/auth", {
            method: "post",
            body: JSON.stringify(j),
            headers: { "Content-type": "application/json;charset=utf-8" },
        });
        res = await res.json();
        if (res.status === "error")
            Notice.Send({ type: "error", text: res.text });
        Store.setSSID(res.ssid);
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};
Api.fetchInit = async () => {
    try {
        let res = await fetch(Api.url + "/v1/init", {
            method: "post",
            body: JSON.stringify({}),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};

Api.v1 = async (path, j, def = {}) => {
    try {
        let res = await fetch(Api.url + "/v1/" + path, {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return def;
    }
};

Api.WinnersList = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/winners/list", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        console.log(res);

        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.WinnersReset = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/winners/reset", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        console.log(res);

        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.WinnersRandom = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/winners/random", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        console.log(res);

        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};

Api.fetchUserList = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/user/list", {
            method: "post",
            body: JSON.stringify({}),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchUserAdd = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/user/add", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};
Api.fetchUserEdit = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/user/edit", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        Notice.Send(res);
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};

Api.fetchOrderList = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/order/list", {
            method: "post",
            body: JSON.stringify({}),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchOrderEdit = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/order/edit", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};

Api.fetchUploadImage = async (f) => {
    try {
        let res = await fetch(Api.url + "/v1/upload", {
            method: "POST",
            body: f,
            headers: { ssid: Store.getSSID() },
        });
        console.log(res);
        res = await res.json();

        console.log(res);

        //  if(res.status === 'logaut') return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchUploadImageVk = async (f) => {
    try {
        let res = await fetch(Api.url + "/v1/widgets/upload", {
            method: "POST",
            body: f,
            mode: "cors",
            headers: { ssid: Store.getSSID() },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchBannerUpload = async (f) => {
    try {
        let res = await fetch(Api.url + "/v1/banner/upload", {
            method: "POST",
            body: f,
            mode: "cors",
            headers: { ssid: Store.getSSID() },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchWidgetsUpdate = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/widgets/update", {
            method: "POST",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return {};
    }
};
Api.fetchWidgetsText = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/widgets/text", {
            method: "POST",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return {};
    }
};

Api.fetchWidgetsStatus = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/widgets/status", {
            method: "POST",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return {};
    }
};
Api.fetchBannerUpdate = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/banner/update", {
            method: "POST",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return {};
    }
};
Api.fetchStocksList = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/stocks/list", {
            method: "post",
            body: JSON.stringify({}),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchStocksStatus = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/stocks/status", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        console.log(e);
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchStocksTest = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/stocks/test", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        Notice.Send(res);
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchStocksData = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/stocks/data", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchStocksDelete = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/stocks/delete", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchStocksCreate = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/stocks/create", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchStocksEdit = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/stocks/edit", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchStocksIcon = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/stocks/icon", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchStocksLock = async () => {
    try {
        let res = await fetch(Api.url + "/v1/stocks/lock", {
            method: "post",
            body: JSON.stringify({}),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchStocksDocument = async (f) => {
    try {
        let res = await fetch(Api.url + "/v1/document", {
            method: "POST",
            body: f,
            headers: { ssid: Store.getSSID() },
        });
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.StocksDuplicate = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/duplicate", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.StocksSubscription = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/stocks/subscription", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.StocksDemonstrate = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/stocks/demonstrate", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        Notice.Send(res);
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.StocksAssent = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/stocks/assent", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        Notice.Send(res);
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.StocksAssentGet = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/stocks/assentget", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};

Api.PlayerList = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/player/list", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.PlayerCheques = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/player/cheques", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.PlayerUsers = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/player/users", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};

Api.TelegramList = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/telegram/list", {
            method: "post",
            body: JSON.stringify({}),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.TelegramLock = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/telegram/lock", {
            method: "post",
            body: JSON.stringify({}),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.TelegramData = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/telegram/data", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.TelegramEdit = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/telegram/edit", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.TelegramAssent = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/telegram/assent", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        Notice.Send(res);
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.TelegramAssentGet = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/telegram/assentget", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.TelegramDuplicate = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/telegram/duplicate", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.TelegramChequesList = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/tbcheques/list", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.TelegramChequesReload = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/tbcheques/reload", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.TelegramChequesStatus = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/tbcheques/status", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.TelegramChequesTicket = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/tbcheques/ticket", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.TelegramChequesEdit = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/tbcheques/edit", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.TelegramStatus = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/telegram/status", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        console.log(e);
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.TelegramDemonstrate = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/telegram/demonstrate", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        Notice.Send(res);
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.TelegramSubscription = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/telegram/subscription", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.TelegramTest = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/telegram/test", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        Notice.Send(res);
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};

Api.TelegramBannerUpdate = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/banner/update", {
            method: "POST",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return {};
    }
};

Api.Telegram = async (path, j) => {
    try {
        let res = await fetch(Api.url + "/v1/telegram/" + path, {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};

Api.fetchChequesList = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/cheques/list", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchChequesReload = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/cheques/reload", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};

Api.ChequesStatus = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/cheques/status", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchChequesTicket = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/cheques/ticket", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.ChequesEdit = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/cheques/edit", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};

Api.fetchQuesList = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/ques/list", {
            method: "post",
            body: JSON.stringify({}),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchQuesData = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/ques/data", {
            method: "post",
            body: JSON.stringify({}),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};

Api.fetchCompanyList = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/company/list", {
            method: "post",
            body: JSON.stringify({}),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchCompanyEdit = async (j) => {
    try {
        let res = await fetch(Api.url + "/v1/company/edit", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        Notice.Send(res);
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};

Api.fetchTelegram = async (j) => {
    try {
        let res = await fetch("https://sp.elcrm.online/v1/telegram", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};

Api.fetchStaff = async (j) => {
    try {
        let res = await fetch("https://sp.elcrm.online/v1/staff", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        if (res.status === "logaut") return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};

Api.fetchParse = async (id) => {
    try {
        let res = await fetch(
            "https://www.wildberries.ru/webapi/seller/data/short/688123"
        );
        res = await res.json();
        return res;
    } catch (e) {
        console.log(e);
    }
};

Api.fetchProductParse = async (j) => {
    try {
        let res = await fetch("https://" + Store.getSITE() + "/upload/base", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};
Api.fetchProductList = async (j) => {
    try {
        let res = await fetch("https://" + Store.getSITE() + "/products/list", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();

        if (res.status === "logout") {
            Store.logaut();
            return [];
        }

        if (res.status === "error") {
            Notice.Send({ type: "error", text: "Ошибка с сервером" });
            return [];
        }

        return res.data;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return [];
    }
};
Api.fetchProductEdit = async (j) => {
    try {
        let res = await fetch("https://" + Store.getSITE() + "/products/edit", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        Notice.Send({ type: res.status, text: res.text });
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};
Api.fetchProductTitle = async (j) => {
    try {
        let res = await fetch(
            "https://" + Store.getSITE() + "/products/title",
            {
                method: "post",
                body: JSON.stringify(j),
                headers: {
                    "Content-type": "application/json;charset=utf-8",
                    ssid: Store.getSSID(),
                },
            }
        );
        res = await res.json();
        Notice.Send({ type: res.status, text: res.text });
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};

Api.fetchProductInfo = async (j) => {
    try {
        let res = await fetch("https://" + Store.getSITE() + "/products/info", {
            method: "post",
            body: JSON.stringify({ id: j }),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};
Api.fetchProductAdd = async (j) => {
    try {
        let res = await fetch("https://" + Store.getSITE() + "/products/add", {
            method: "post",
            body: JSON.stringify({}),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};
Api.fetchProductLinks = async () => {
    try {
        let res = await fetch(
            "https://" + Store.getSITE() + "/products/links",
            {
                method: "post",
                body: JSON.stringify({}),
                headers: {
                    "Content-type": "application/json;charset=utf-8",
                    ssid: Store.getSSID(),
                },
            }
        );
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};

Api.fetchCategoriesList = async (j) => {
    try {
        let res = await fetch(
            "https://" + Store.getSITE() + "/categories/list",
            {
                method: "post",
                body: JSON.stringify(j),
                headers: {
                    "Content-type": "application/json;charset=utf-8",
                    ssid: Store.getSSID(),
                },
            }
        );
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};

Api.fetchCategoriesAdd = async () => {
    try {
        let res = await fetch(
            "https://" + Store.getSITE() + "/categories/add",
            {
                method: "post",
                body: JSON.stringify({}),
                headers: {
                    "Content-type": "application/json;charset=utf-8",
                    ssid: Store.getSSID(),
                },
            }
        );
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};

Api.fetchCategoriesEdit = async (j) => {
    try {
        let res = await fetch(
            "https://" + Store.getSITE() + "/categories/edit",
            {
                method: "post",
                body: JSON.stringify(j),
                headers: {
                    "Content-type": "application/json;charset=utf-8",
                    ssid: Store.getSSID(),
                },
            }
        );
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};
Api.fetchCategoriesInfo = async (j) => {
    try {
        let res = await fetch(
            "https://" + Store.getSITE() + "/categories/info",
            {
                method: "post",
                body: JSON.stringify({ id: j }),
                headers: {
                    "Content-type": "application/json;charset=utf-8",
                    ssid: Store.getSSID(),
                },
            }
        );
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};

Api.fetchCommentList = async (j) => {
    try {
        let res = await fetch("https://" + Store.getSITE() + "/comment/list", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};

Api.fetchCommentEdit = async (j) => {
    try {
        let res = await fetch("https://" + Store.getSITE() + "/comment/edit", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        Notice.Send({ type: res.status, text: res.text });
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};

Api.fetchCommentInfo = async (j) => {
    try {
        let res = await fetch("https://" + Store.getSITE() + "/comment/info", {
            method: "post",
            body: JSON.stringify({ id: j }),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};

Api.fetchCommentDelete = async (j) => {
    try {
        let res = await fetch(
            "https://" + Store.getSITE() + "/comment/delete",
            {
                method: "post",
                body: JSON.stringify({ id: j }),
                headers: {
                    "Content-type": "application/json;charset=utf-8",
                    ssid: Store.getSSID(),
                },
            }
        );
        res = await res.json();
        Notice.Send({ type: res.status, text: res.text });
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};

Api.fetchInfoList = async (j) => {
    try {
        let res = await fetch("https://" + Store.getSITE() + "/info/list", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};
Api.fetchInfoEdit = async (j) => {
    try {
        let res = await fetch("https://" + Store.getSITE() + "/info/edit", {
            method: "post",
            body: JSON.stringify(j),
            headers: {
                "Content-type": "application/json;charset=utf-8",
                ssid: Store.getSSID(),
            },
        });
        res = await res.json();
        Notice.Send({ type: res.status, text: res.text });
        return res;
    } catch (e) {
        Notice.Send({ type: "error", text: "Ошибка с сервером" });
        return { status: "error" };
    }
};

Api.fetchMainLoad = async (j) => {
    let res = await fetch("https://" + Store.getSITE() + "/main/load", {
        method: "post",
        body: JSON.stringify(j),
        headers: {
            "Content-type": "application/json;charset=utf-8",
            ssid: Store.getSSID(),
        },
    });
    res = await res.json();
    return res;
};

Api.fetch = (address, params = address) =>
    new Promise((resolve) => {
        try {
            // получаем данные и заполняем значения по умолчанию
            let {
                defaultURL = Api.url,
                contentType = "application/json;charset=utf-8",
                method = "get",
                type = "json",
                headers = {},
                body,
                query,
                path,
                host = typeof address === "string" ? address : defaultURL,
            } = params;

            // переводем все зоголовки в нижней регистр для удобства
            headers = JSON.parse(JSON.stringify(headers).toLowerCase());

            // получаем class URL для обработки данных
            let url = new URL(host);

            // !!!! перезаписывем пути если они указаны
            url.pathname = path || url.pathname;

            // если есть праметр QUERY то тогда добавляем их к URL
            if (query)
                Object.keys(query).forEach((i) =>
                    url.searchParams.set(i, query[i])
                );

            // если есть BODY то делаем автоматичке POST запрос
            if (body) {
                // если указан METHOD тогда меняем его на указанный или оставляем POST
                method = method.toLowerCase() === "get" ? "post" : method;
                // добавляем автоматичке заголовок CONTENT-TYPE по умолчанию
                headers = { "content-type": contentType, ...headers };
            }

            // получаем class XMLHttpRequest для отпарвки данных
            let xhr = new XMLHttpRequest();

            // обработка ответа с сервера
            const onload = () => {
                if (xhr.status === 200) {
                    // успешный ответ
                    resolve({ response: xhr.response });
                } else {
                    // ответ ошибки
                    resolve({
                        error: { code: xhr.status, text: xhr.statusText },
                    });
                }
            };

            // подписываем оброботчик на ответ с сервера
            xhr.onload = onload;
            // создаем запрос и заполняем данными
            xhr.open(method, url, true);
            // если тип ожидаемых данных JSON то тогда указывае в запросе
            if (type.toLowerCase() === "json") xhr.responseType = "json";
            // добавляем указанные заголовки в запрос
            Object.keys(headers).forEach((i) =>
                xhr.setRequestHeader(i, headers[i])
            );

            // отпарвляем данные в зависимости от типа данных
            switch (true) {
                case headers["content-type"] === undefined:
                    xhr.send();
                    break;
                case body instanceof FormData:
                    xhr.send(body);
                    break;
                case headers["content-type"].includes(
                    "application/x-www-form-urlencoded"
                ):
                    xhr.send(new URLSearchParams(body).toString());
                    break;
                case headers["content-type"].includes("application/json"):
                    xhr.send(JSON.stringify(body));
                    break;
                default:
                    xhr.send(body);
            }
        } catch (err) {
            // если ошибка то тогда отправляем error
            resolve({ error: { code: 0, text: "Запрос не удался", log: err } });
        }
    });

export default Api;
